import React from 'react';
import {createQt, postQuestions, inviteChat, closeChat} from "../api/service";
var clickTimer0;
var clickTimer1;
var clickTimer2;
var clickTimer3;
var inputTimer1;
var inputTimer2;

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const states = children.props.children.props.state;

  const next = async (id, message) => {

      const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
      const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
      const input = document.querySelector('.react-chatbot-kit-chat-input');
      const loading_point = document.createElement('span');
      const next_point = document.createElement('span');
      loading_point.className = 'loading-points';
      loading_point.append(next_point);
      icon.style.display = 'none';
      btn.append(loading_point);

      let idQt = states.idQt;
      if (!idQt) {
          const pq = await createQt();
          idQt = pq.data.id;
          setState((prev) => {
              return {...prev, ...{idQt: pq.data.id}}
          });
      }

      let question = {};
      let finalValue = null

      input.placeholder = ' je vérifie votre demande';
      window.clearTimeout(inputTimer1);
      window.clearTimeout(inputTimer2);
      inputTimer1 = window.setTimeout(() => {
          input.placeholder = ' IA écrit ';
      },5*1000);
      inputTimer2 = window.setTimeout(() => {
          input.placeholder = ' j\'arrive... ';
      },15*1000);
      question.question = '';
      if(idQt) {
          const pqapi = await postQuestions(idQt, {"message": message});
          question.question = pqapi.data.output;
      }
      window.clearTimeout(inputTimer1);
      window.clearTimeout(inputTimer2);
      input.placeholder = 'Message';
      icon.style.display = 'block';
      loading_point.remove();

      const allDivs = document.querySelectorAll('.react-chatbot-kit-chat-bot-message');
      const lastElement = allDivs[allDivs.length - 1];
      setTimeout(() => {
          lastElement.scrollIntoView()
      }, 3000)

      if (question && question.final) {
          finalValue = question.value
          setState(prevState => ({
              ...prevState,
              skinType: finalValue
          }))
          id = question.next//states.selfie ? question.next : 28
      }
      let str = question.question;
      let obj = {
          payload: {uid: id, id: question.id},
          loading: true,
          terminateLoading: true,
          withAvatar: true,
          delay: question.delay || null,
          widget: question.widget || null,
      }

      addMessageToState(createChatBotMessage(str, obj));
      // clearTimeOut(idQt, str, obj, lastElement);
      // document.body.addEventListener("keydown", () => {
      //     clearTimeOut(idQt, str, obj, lastElement);
      // });
  }

  const clearTimeOut = (idQt, str, obj, lastElement) => {
      window.clearTimeout(clickTimer0);
      window.clearTimeout(clickTimer1);
      window.clearTimeout(clickTimer2);
      window.clearTimeout(clickTimer3);
      clickTimer0 = window.setTimeout( async () => {
          const inviteChatRes = await inviteChat(idQt);
          addMessageToState(createChatBotMessage(inviteChatRes.data.output, obj));
      },30*1000);
      clickTimer1 = window.setTimeout(() => {
          addMessageToState(createChatBotMessage(str, obj));
      },60*1000);
      clickTimer2 = window.setTimeout(() => {
          addMessageToState(createChatBotMessage('Est ce que vous êtes toujours la?', obj));
          setTimeout(() => addMessageToState(createChatBotMessage(str, obj)), 3000);
          setTimeout(() => lastElement.scrollIntoView(), 4000);
      },100*1000);
      clickTimer3 = window.setTimeout( async () => {
          const closeChatRes = await closeChat(idQt);
          addMessageToState(createChatBotMessage(closeChatRes.data.output, obj));
          document.querySelector('.react-chatbot-kit-chat-input-form').innerHTML = '<a href="/" style="margin: auto; color: black;">Recommencer</a>';
      },140*1000);
  }

  const createBotMessage = (msg, options= {}) => {
    const message = createChatBotMessage(
        msg,
        options
    );
    addMessageToState(message)
  };

  const addMessageToState = (message) => {
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
      <div>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            actions: {next,  createBotMessage},
          });
        })}
      </div>
  );
};

export default ActionProvider;
